// COLORS
$main-gray:			#1b1c20;
$light-gray:		#3f3f3f;
$lighter-gray:      #797979;
$terrenos-gray01: 	#333333;
$terrenos-gray02: 	#575757;
$terrenos-golf: 	#f1be50;
$terrenos-silver: 	#cccccc;
$terrenos-premium: 	#a54600;
$brown:             #ad6343; 	
$black:				#000;
$white:				#fff;


// BREAKPOINTS
@mixin breakpoint($point) {
	@if $point == superbigdesktop {
		@media (min-width: 1920px) { @content ; }
	}
	@if $point == bigdesktop {
		@media (min-width: 1600px) { @content ; }
	}
	@if $point == desktop {
		@media (min-width: 1440px) { @content ; }
	}
	@else if $point == laptop {
		@media (min-width: 1200px) { @content ; }
	}
	@else if $point == tablet {
		@media (min-width: 1024px) { @content ; }
	}
	@else if $point == phablet {
		@media (min-width: 768px)  { @content ; }
	}
	@else if $point == phabletonly {
		@media (min-width: 768px) and (max-width: 1024px) { @content ; }
	}
	@else if $point == largemobile {
		@media (min-width: 361px)  { @content ; }
	}
	@else if $point == phargemobile {
		@media (max-width: 768px) and (orientation: landscape) { @content ; }
	}
}