.wrapper.home .video-gallery-container {
    background-color: #1e1f24;
    display: flex;
    width: 100%;
    padding: 40px 0;
}

.wrapper.home .video-gallery-container .video-wrapper {
    max-width: 991px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}


.wrapper.home .video-gallery-container .video-wrapper figure {
    margin: 15px;
    display: flex;
    max-width: 215px;
    flex-direction:column;
    align-items:center;
    position: relative;
}


.wrapper.home .video-gallery-container .video-wrapper figure a {
    position: relative;
    z-index: 10;
}

.wrapper.home .video-gallery-container .video-wrapper figure a img {
    max-width: 100%;
}

.wrapper.home .video-gallery-container .video-wrapper figure a .play-icon {
    margin: auto;
    max-width: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}

.wrapper.home .video-gallery-container .video-wrapper figure img, .wrapper.home .video-gallery-container .video-wrapper figure figcaption{
    padding-bottom: 10px;
}

.wrapper.home .video-gallery-container .video-wrapper figure .share-icon.share, .wrapper .galeria .share-icon.share {
    max-width: 19px;
    cursor: pointer;
    z-index: 3;
    opacity: 1;
}

.wrapper.home .video-gallery-container .video-wrapper figure .share-icon, .wrapper .galeria .share-icon {
    max-width: 30px;
    cursor: pointer;
    z-index: 2;
    opacity: 1;
    margin-top: 10px;
}

.video-wrapper figure .social-share {
    display: flex;
    width: 30px;
    flex-direction: column;
    position: absolute;
    align-items: center;
    padding: 5px 10px 5px 10px;
    left: 50%;
    transform: translateX(-50%);
    top: 42%;
    z-index: 2;
    opacity: 0;
    margin-bottom: 15px;
    background: url('../img/bg-tabtitle.jpg') 0 0 repeat;
}

.wrapper .galeria .social-share {
    display: flex;
    width: 30px;
    flex-direction: column;
    position: absolute;
    align-items: center;
    padding: 5px 10px 15px 10px;
    left: 51%;
    transform: translateX(-50%);
    bottom: 9%;
    z-index: 10;
    opacity: 0;
    margin-bottom: 15px;
    background: url('../img/bg-tabtitle.jpg') 0 0 repeat;
}

.single-gallery .social-share a { display: block; }

.single-gallery .social-share a img { display: block; }

.single-gallery .share-icon:hover ~ .social-share {
    opacity: 1;
}

.video {
    display: none;
    width: auto;
}

@media screen and (max-width: 1023px) {
    .wrapper.home .video-gallery-container .video-wrapper {
        justify-content: center;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .wrapper.home .video-gallery-container .video-wrapper figure {
        width: 40%;
        max-width: none;
    }
}

.to-emb {
    display: flex;
}

.hidden {
    display: none;
}

.video.contacto {
    padding: 35px;
    background-color: black;
    color: #c66b44;
    font-family: 'ProximaNova regular', sans-serif;
    font-size: 13px;
    font-weight: bold;
}

.input-contact {
    background-color: black;
    border: none;
    border-bottom: 1px solid #c66b44;
    width: 100%;
    margin: 20px 0; 
    outline: none;
}

.input-contact:active, .input-contact:focus, .input-contact:hover, {
    outline: none;
}

.wpcf7-submit {
    border-radius: 0;
    padding: 10px;
    width: 100%;
    background-color: transparent;
    border: 1px solid;
    outline: none;
}

.screen-reader-response {
    display: none !important;
}

.wpcf7-validation-errors {
    margin: 10px 0;
}

/* PAGE REAL ESTATE */

.realestate.wrapper {
    position: relative;
    overflow: visible;

    @include breakpoint(phablet) {
        overflow: hidden;
        
        .page-template-page-realestate-inner & {
            overflow: visible;
            height: auto;
        }
    }

    @include breakpoint(tablet) {
        height: calc(100vh - 142px);
    }
}

.realestate {

    &__hero {
        background-position: center;
        background-size: cover;
        height: calc(85vh - 90px);
        position: relative;
        
        @include breakpoint(tablet) {
            height: calc(100% - 267px);
        }

        @include breakpoint(laptop) {
            height: calc(100% - 225px);
        }

        @include breakpoint(bigdesktop) {
            height: calc(100% - 183px);
        }

        .page-template-page-realestate-inner & {
            display: flex;
            align-items: center;
            
            @include breakpoint(tablet) {
                height: calc(85vh - 142px);
            }

            &.img__overlay {

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.2);
                }
            }
        }

        h1 {
            font: 700 26px "freight-big-pro", sans-serif;
            text-transform: uppercase;
            color: $white;
            margin: auto;
            position: relative;

            @include breakpoint(phablet) {
                font-size: 50px;
            }
        }
    }

    &__tagline {
        background: #1b1c20;
        padding: 60px 0;
        
        h1 {
            padding: 0 26px;
            font: 17px/21px Garamond, 'Baskerville', 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
            color: #fff;
            text-align: center;
            
            @include breakpoint(tablet) {
                width: 40%;
                margin: auto;
            }
        }
    }

    &__map {
        background: $main-gray;
        
        .contentCenter {
            width: auto;
            max-width: 1100px;

            @include breakpoint(tablet) {
                display: flex;
            }
        }
        
        figure {
            text-align: center;
            padding: 50px 0;
            
            @include breakpoint(tablet) {
                flex: 0 0 50%;
                text-align: left;
            }
            
            img {
                width: 230px;
            }
        }

        &-content {
            text-align: center;
            padding: 0 30px 50px;
            
            @include breakpoint(tablet) {
                flex: 0 0 50%;
                padding: 50px 0;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                text-align: left;
            }            
            
            p {
                font: 17px/21px Garamond, 'Baskerville', 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
                margin-bottom: 24px;
                
                &:last-child {
                    margin: 0;
                }
            }

            .map-content-legal {

                p {
                    font-size: 14px;
                    font-style: italic;
                    line-height: 18px;
                    letter-spacing: 0.5px;
                    margin-bottom: 10px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }

    &__models {
        overflow: hidden;
    
        &--select {
            background: url(../img/bg-footer.jpg) center;
            padding: 50px 0;

            h1 {
                font: 700 26px/24px "freight-big-pro", sans-serif;
                text-transform: uppercase;
                color: $white;
                margin-bottom: 35px ;     
                padding-top: 35px;       
                position: relative;
                
                &:before {
                    content: '';
                    width: 84px;
                    height: 1px;
                    background: $white;
                    position: absolute;
                    top: 0;
                    left: 0; 
                }
            }

            .options {
                margin-left: -20px;
                margin-right: -20px;
                
                @include breakpoint(phablet) {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                }
                
                li {
                    height: 100px;
                    background-size: cover;
                    background-position: center;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    transition: all 1s ease;
                    position: relative;
                    cursor: pointer;
                        
                    &:after {
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,.8);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;  
                        transition: all 1s ease;
                    }  
                    
                    @include breakpoint(phablet) {
                        flex: 0 0 32%;
                        height: 142px;
                    }

                    @include breakpoint(laptop) {
                        height: 242px;
                        margin-bottom: 0px;       
                        
                        &:hover:after {
                            background: rgba(0,0,0,0);
                        }                                                                      
                    }

                    h2 {
                        font: 700 26px/24px "freight-big-pro", sans-serif;
                        text-transform: uppercase;
                        color: $white;
                        margin: auto;
                        position: relative;
                        z-index: 3;
                    }

                    &.active {
                        height: 242px;

                        &:after {
                            background: rgba(0,0,0,0);
                        }
                        
                        @include breakpoint(phablet) {
                            height: 142px;
                        }                        

                        @include breakpoint(laptop) {
                            height: 242px;
                        }                        
                    }
                }
            }


        }

        &--unit {
            background: url(../img/bg-header.jpg) center center repeat;
            
            .gallery {
                position: relative;
                
                .slides {
                    height: 65vh;

                    li {
                        height: 65vh;
                        background-size: cover;
                        background-position: center;

                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            transition: opacity 0.3s ease;
                        }
                    }
                }
                .flex-direction-nav {
                    display: flex;
                    justify-content: flex-end;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 2;

                    li {
                        margin: 0 14px 14px 0;
                        
                        a {
                            display: block;
                            
                            svg {
                                height: 38px;
                                width: 38px;
                                transform: rotate(90deg);
                                
                                &.nextArrow {
                                    transform: rotate(-90deg); 
                                }

                                .arrow_st0 {
                                    fill: #fff;
                                }

                                .arrow_st1 {
                                    stroke: #fff;
                                }
                            }

                            &:hover {

                                svg {

                                    .arrow_st0 {
                                        fill: #c66b44;
                                    }

                                    .arrow_st1 {
                                        stroke: #c66b44;
                                    }
                                }
                            }
                        }
                    }
                }

            }

            .contentCenter {
                
                @include breakpoint (tablet) {
                    display: flex;
                }
                
                .detail {
                    background: $light-gray;
                    margin-left: -20px;
                    margin-right: -20px;
                    padding: 60px 20px;
                    position: relative;
                    box-sizing: border-box;
                    
                    @include breakpoint (tablet) {
                        order: 2;
                        flex: 0 0 50%;
                        margin-left: 0;
                        padding: 60px;
                        padding-right: 0;

                        &:after {
                            content: '';
                            background: $light-gray;
                            width: 20px;
                            height: 100%;
                            position: absolute;
                            left: 100%;
                            top: 0;
                            bottom: 0;
                        }
                    }

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        flex: 0 0 39%;
                    }
                    
                    @include breakpoint (laptop) {

                        &:after {
                            width: 100%;
                        }
                    }
                    
                    h2 {
                        font: 700 26px/24px "freight-big-pro", sans-serif;
                        text-transform: uppercase;
                        color: $white;
                        padding-bottom: 20px;  
                        margin-bottom: 35px;     
                        position: relative;
                        
                        &:before {
                            content: '';
                            width: 38px;
                            height: 1px;
                            background: $white;
                            position: absolute;
                            bottom: 0;
                            left: 0; 
                        }
                    }

                    p {
                        font: 14px 'ProximaNova semi';
                        line-height: 38px;
                        border-bottom: 1px $lighter-gray solid;
                        display: flex;
                        justify-content: space-between;                        
                        
                        .title {
                            text-transform: uppercase;
                        }
                        .units {
                            color: $brown;
                        }
                    }

                    .more {
                        font-family: 'ProximaNova semi';
                        color: $white;
                        font-size: 10px;
                        text-transform: uppercase;
                        display: block;
                        margin-top: 42px;

                        &:hover { color: #c66b44; }
                    }

                }

                .content {
                    padding: 50px 0;
                    box-sizing: border-box;
                    
                    @include breakpoint (tablet) {
                        order: 1;
                        flex: 0 0 50%;
                        padding: 60px;
                        padding-left: 0;
                    }                    

                    p {
                        font: 17px/21px Garamond, 'Baskerville', 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
                        margin-bottom: 24px;
                        color: $white;
                        
                        &:last-child {
                            margin: 0;
                        }
                    }

                    &.contact .send,
                    &.contact .send-ib {
                        float: none;
                        width: auto;
                        height: auto;
                        color: $white;
                        border-color: $white;
                        background: none;
                        padding: 8px 20px;
                        
                        .text {
                            color: $white;
                            font-size: 14px;
                        }
                    }

                    &.contact .send-ib  {
                        display: inline-block;
                    }
                }
            }
        }

    }

    .openInfo {
        width: 130px;
    }

    .overitems {
        top: calc(85vh - 90px);
        
        @include breakpoint(phablet) {
            top: auto;
        }

        &.open {
            top: calc(85vh - 90px);
            height: 650px;

            @include breakpoint(phablet){
                height: 287px;
                top: auto;
            }
        }

        .content {

            .normal {
                padding-bottom: 18px;
                background: url('../img/realestate/submenu-bottom.png') no-repeat bottom;
            }

            @include breakpoint(phablet) {
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (min--moz-device-pixel-ratio: 1.5),
       only screen and (min-device-pixel-ratio: 1.5){

    #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
        background: url(../img/fancy/ic-close.png) center center no-repeat !important;
    }
}