body.is-scrolling-map {
	cursor: move;
}

.realestate {

	.page-template-page-terrenos-php & {
		height: auto;
		
		* {box-sizing: border-box;}
		
		&__draggable-menu {
			position: absolute;
			bottom: 0;	
			z-index: 4;
			width: 100%;
			
			@include breakpoint(tablet) {
				background-color: rgba(63,63,63,1);
				top: 0;
				bottom: inherit;
			}
			
			.contentCenter {
				display: flex;
				justify-content: space-between;
				padding: 0;
				
				@include breakpoint(tablet) {
					padding: inherit;
				}
				
				a {
					color: $white;
					text-transform: uppercase;
					line-height: 48px;
					font-family: 'ProximaNova semi', sans-serif;
					display: inline-block;

					&.back {
						display: none;

						@include breakpoint(tablet) {
							display: block;
						}

						svg {
						    width: 17px;
						    height: 10px;
						    transform: rotate(90deg);						
						    margin-right: 4px;
						}
					}
				}

				.type-of {
					display: flex;
					width: 100%;
					
					li {
						flex: 1 1 auto;
						text-align: center;
						background-color: rgba(63,63,63,1);
						    
						&:first-child {
							display: none;
						}

						a {
							display: block;
							padding: 0 13px;
							color: inherit;
						}

						&.golf {
							color: $terrenos-golf;
							
							&.active {
								color: $white;
								background-color: $terrenos-golf;
							}
						}

						&.silver {
							color: $terrenos-silver;
							
							&.active {
								color: $white;
								background-color: $terrenos-silver;
							}								
						}

						&.premium {
							color: $terrenos-premium;
							
							&.active {
								color: $white;
								background-color: $terrenos-premium;
							}								
						}
					}

					@include breakpoint(tablet) {
						flex-direction: row;
						width: auto;

						li {
							line-height: 48px;
							transition: all .5s ease;
							
							&:first-child {
								display: block;
							}							
							
							&:nth-child(even) {
								flex: 2 1 auto;
							}

							a { 
								padding: 0 60px;
							}

							&.active, &:hover {
								background-color: $brown;
							}
							&.golf.active, &.golf:hover {
								background-color: $terrenos-golf;
							}
							&.silver.active, &.silver:hover {
								background-color: $terrenos-silver;
							}
							&.premium.active, &.premium:hover {
								background-color: $terrenos-premium;
							}

							&.golf, &.silver, &.premium {
								color: $white;
							}
						}
					}
				}
			}
		}

		&__draggable-submenu {
			position: absolute;
			z-index: 3;
			background-color: rgba(63,63,63,.95);
			width: 100%;
			bottom: 48px;
			
			.contentCenter {
				padding: 0;
				
				@include breakpoint(tablet) {
					padding: inherit;
				}
			}
			
			@include breakpoint(tablet) {
				top: 48px;
				bottom: inherit;
			}				
			.submenu {

				&-contents {
										
					li {
						visibility: hidden;
						opacity: 0;
						height: 0;
						padding: 0;
						display: flex;
						
						&.active {
							visibility: visible;
							opacity: 1;
							height: 100%;
							
							@include breakpoint(tablet) {
								padding: 20px 0;
							}
						}

						&.golf .submenu-name h3 { color: $terrenos-golf; }
						&.silver .submenu-name h3 { color: $terrenos-silver; }
						&.premium .submenu-name h3 { color: $terrenos-premium; }
					}
				}

				&-name {
					display: none;

					@include breakpoint(tablet) {
						flex: 1 1 20%;
						padding-right: 16px;
						border-right: 1px $white solid;
						display: flex;
					    flex-direction: column;
					    justify-content: center;					
						
						
						h3 {
							color: $white;
							text-transform: uppercase;
							line-height: 43px;
							font-size: 16px;
							text-align: right;
						}
					}
				}
				&-details {
					display: none;

					@include breakpoint(tablet) {					
						display: flex;
						flex: 2 1 50%;
						padding-left: 20px;
					    flex-direction: column;
					    justify-content: space-around;
						
						p {
							font: 12px 'ProximaNova semi', sans-serif;
							color: $white;
							text-transform: uppercase;
							line-height: 1.6;
						}	
					}				
				}
				&-availability {
					flex: 1 1 30%;
					display: flex;
				    justify-content: center;	
				    
				    .send {
				    	width: 100%;
				    	border: none;
				    	
						@include breakpoint(tablet) {
							width: auto;
							border: 1px $white solid;
						}
				    }				
				}
			}
		}

		&__draggable-map {
			height: 100%;
			max-height: calc(100vh - 181px);
			position: relative;
			overflow: hidden;

			@include breakpoint(tablet) {			
				height: calc(100vh - 142px);
				max-height: 100vh;
			}

			@include breakpoint(laptop) {
				max-height: calc(100vh - 90px);
			}

			.map-scroll-handler {
				width: 100%;
				height: 100%;
				max-height: 100vh;
				position: absolute;
				z-index: 1;
				cursor: move;
			}

			.map-img-wrapper {
				width: calc(100% + 20px);
				height: 100%;
				max-height: calc(100vh - 181px);
				overflow: auto;
				pointer-events: none;
				
				@include breakpoint(tablet) {
					height: calc(100% + 20px);
					max-height: calc(100vh + 20px);
				}
				
			}

			.map-scroll-advice {
				z-index: 3;
				opacity: 0;
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba($terrenos-gray02, 0.9);
				color: $white;
				font-size: 30px;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				font-weight: bold;
				transition: opacity 0.3s ease;
			}

			.map-img {
				min-width: 100%;
				
				* { 
					stroke-linecap: round;
					stroke-linejoin:round;
					stroke-miterlimit:10;
					stroke: $black;
				}
				polygon, path, polyline {
					opacity: 0;
				}
				[data-type="golf-fronts"] * {
					fill: $terrenos-golf;
					opacity:1;
				}
				[data-type="premium"] * {
					fill: $terrenos-premium;
					opacity:1;
				}
				[data-type="silver"] * {
					fill: $terrenos-silver;
					opacity:1;
				}
				[data-available="false"] *, .inactive * {
					opacity: 0;
				}
				text {
					font-size: 10px;
					font-family: 'ProximaNova semi', sans-serif;
					stroke: none;
					pointer-events: none;
				}
			}

			.zoom-controls {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				background: $brown;
				z-index: 2;

				.btn {
					display: block;
					-webkit-appearance: none;
					background: transparent;
					border: 0;
					padding: 10px;
					outline: 0;
					
					svg {
						width: 24px;
						height: 24px;
					}
				}
			}

			&.cant-scroll {

				.map-scroll-advice {
					opacity: 1;
					pointer-events: auto;
					transition: opacity 0.7s cubic-bezier(0.3, -0.46, 0.25, 1);
				}
			}

			&.show-map {

				.map-img-wrapper {
					pointer-events: auto;
				}

				.map-scroll-handler {
					display: none !important;
				}
			}
		}

		&__draggable-contain {
			position: relative;
			
			@include breakpoint(tablet) {
				height: calc(100vh - 142px);
				max-height: 100vh;
			}
		}

		&__draggable-details {
			background-color: rgba(63,63,63,.95);
			height: 100%;
			transition: all .5s ease;
			position: absolute;
			width: 100%;
			z-index: 4;
			height: 0;
			visibility: hidden;
			top: -100%; 
			overflow: hidden;

			&.active {
				visibility: visible;
				height: 100%;
				top:0;
				left:0;
				right:0;
				bottom:0;
			}
			
			.wrapper__inner {
				width: 100%;
				padding-top: 20px;
				margin: auto;
				visibility: hidden;
				opacity: 0;
				transition: all .35s ease;
				height: 0;
				padding: 0;			
				display: flex;
			    flex-direction: column;
			    justify-content: center;
				
				&.active {
					visibility: visible;
					opacity: 1;
					padding: 40px;
					height: 100%;

					@include breakpoint(phablet) {
						padding-left: 0;
						padding-right: 0;
						padding: 88px 20px 60px;
					}

				}

				&.contact {
					/*display: flex;
				    flex-direction: column;
				    justify-content: center;*/
				    
				    input {
				    	border-radius: 0;
				    }
				}

				.close {
					background: url("../img/fancy/ic-close.png") center center no-repeat;
					text-indent: -9000px;
					display: block;
					height: 28px;
					width: 29px;
					transition: all .35s ease;
					align-self: flex-end;
    				margin-bottom: -28px;
    				z-index: 3;
					
					&:hover {
						transform: rotate(90deg);
					}

					@include breakpoint(tablet) {					
						margin-right: -100px;
					}
				}

				@include breakpoint(phablet) {
					max-width: 400px;	
					
					&.contact.active {
						padding: 88px 0 60px;
					}
				}

				.units-list {
					overflow-y: scroll;
					height: 55%;
					
					@include breakpoint(phablet) {
						height: 80%;
					}

					li {
						background: $terrenos-gray02;
						line-height: 46px;
						padding: 0 12px;
										
						&:nth-child(even) {
							background-color: $terrenos-gray01;
						}

						&:hover {
							background-color: $main-gray;	
						}

						.name {
							display: block;
							color: $white;

							&:before {
								content: "";
								display: inline-block;
								background: $terrenos-golf;
								width: 12px;
								height: 12px;
								border-radius: 100%;
								margin-right: 20px;
							}
						}

						.more {
							font: 700 12px/46px 'ProximaNova regular', sans-serif;
							color: $white;
							text-transform: uppercase;
							text-decoration: underline;
						}
					}

				}
			}

			hgroup {
				text-align: center;
				margin-bottom: 20px;
				padding: 0 20px;

				h2 {
					font: 14px 'ProximaNova regular', sans-serif;
					color: $white;
					text-transform: uppercase;
					position: relative;
					padding-bottom: 22px;
					margin-bottom: 20px;
					
					&:after {
						content: "";
						background: $white;
						width: 37px;
						height: 1px;
						position: absolute;
						bottom: 0px;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				h1 {
					font: 700 26px/30px Garamond, 'Baskerville', 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
	                color: $white;
	                text-transform: uppercase;
				}
			}

			p {
				font: 14px 'ProximaNova semi', sans-serif;
				color: $white;
				text-align: center; 
				margin-bottom: 18px;
			}

			form {
				&.error {
					input {
						border-color: red;
						
						&::placeholder {
							color: red;	
						}
					}
				}
			}

			.send { padding: 8px 40px!important;}

			.golf .units-list li .name:before { background: $terrenos-golf; }
			.silver .units-list li .name:before { background: $terrenos-silver; }
			.premium .units-list li .name:before { background: $terrenos-premium; }
		}

		&__draggable-info {
			background: $light-gray;
			text-align: center;
			padding: 32px 20px;

			@include breakpoint(phablet) {
				padding: 32px 0;
			}
			
			h1 {
				font: 700 14px 'ProximaNova regular', sans-serif;
				color: $white;
				text-transform: uppercase;
				position: relative;
				padding-bottom: 22px;
				margin-bottom: 20px;
				
				&:after {
					content: "";
					background: $white;
					width: 37px;
					height: 1px;
					position: absolute;
					bottom: 0px;
					left: 50%;
					transform: translateX(-50%);
				}
			}	

			p {
				font: 17px/21px Garamond, 'Baskerville', 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
	    		color: $white;
	    		margin: 0 auto 24px;
	    		
				@include breakpoint(tablet) {
	    			width: 40%;
	    		}
			}
		}

		.send {
			float: none;
			width: auto;
			height: auto;
			color: $white;
			border-color: $white;
			background: none;
			padding: 8px 20px;
			margin: auto;

			.text {
			    color: $white;
			    font-size: 14px;
			}

			.over {
				background: $white;
			}

			&:hover {
				
				.text {
					color: $brown;	
				}
			}
		}
	}
}