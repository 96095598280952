/* LAYOUT*/
@font-face {
    font-family: 'ProximaNova regular';
    src: url('../font/ProximaNova-Regular.otf');
    font-weight: normal;
}
@font-face {
    font-family: 'ProximaNova semi';
    src: url('../font/ProximaNova-Semibold.otf');
    font-weight: normal;
}

html {
	height: 100%;
}
body {
	font-size: 13px;
	font-family: 'ProximaNova regular', sans-serif;
	font-weight: 400;
	color: #fff;
	background-color: #fff;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
}
.serif {
	font-family: Garamond, 'Baskerville', 'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
	font-weight: 400;
}
h1, h2, h3, h4, h5, h6, p, ul {
	padding: 0;
	margin: 0;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	font-family: 'ProximaNova semi', sans-serif;
}
ul, li {
	list-style: none;
}
a {
	text-decoration: none;
	color: blue;
}
a:hover {
	text-decoration: none;
	color: #666;
}
.clear {
	clear: both;
}
.fl {
	float: left;
}
.fr {
	float: right;
}
.it {
	font-style: italic;
}
.tc {
	text-align: center;
}
.tr {
	text-align: right;
}
.tl {
	text-align: left;
}
.upp {
	text-transform: uppercase;
}
.mbot20 {
	margin-bottom: 20px;
}
.mtop20 {
	padding-top: 20px;
}
.autoClearer:before,
.autoClearer:after {
    content: " ";
    display: table;
}
.autoClearer:after {
    clear: both;
}
.body_IE6 .autoClearer,
.body_IE7 .autoClearer {
    zoom: 1;
}
input[type="radio"],
input[type="checkbox"] {

}
input[type="text"],
input[type="password"],
input[type="email"] {

}
input.short {
	width: 49%;
}

/*:::::::::::::::::::: HEADER - MENU :::::::::::::::::::::::::::*/
.contentCenter {
	width: auto;
	padding: 0 20px;
	margin: 0 auto;
}
header {
	width: 100%;
	height: 142px;
	display: block;
	background: url('../img/bg-header.jpg') center center repeat;
	font-family: 'ProximaNova semi', sans-serif;
}
header .logo {
	width: 133px;
	height: 92px;   
	margin: 25px auto 0 auto;
	float: left;
}
header nav {
	float: right;
	width: 80%;
}
header nav a {
	color: #ffffff;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
header nav a:hover,
header nav a.active {
	text-decoration: none;
	color: #c66b44;
}
header nav .menu {
	display: block;
	text-align: right;
	padding: 30px 0 0 0;
}
header nav .menu li {
	display: inline-block;
	*display: inline;
	zoom: 1;
	color:  #c66b44;
	padding: 0 0 0 5px;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 26px;
	position: relative;
}
header .lang {
	display: block;
	text-align: right;
	font-size: 10px;
	text-transform: uppercase;
	padding: 20px 0 0 0;
	line-height: 25px;
}
header .lang a {
	display: inline-block;
	*display: inline;
	zoom: 1;
	margin: 0 0 0 15px;
}
header .lang a.mr {
	margin-right: 15px;
}

header nav .menu li ul.submenu {
	position: absolute;
	top: 19px;
	padding-top: 23px;
	left: -200px;
	/*width: 750px;*/
	display: flex;
	z-index: -9;
	height: 0;
	overflow: hidden;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

@media screen and (max-width: 1025px) {
	header nav .menu li ul.submenu {
		left: -250px;
	}	
}
header nav .menu li:hover ul.submenu {
	height: 181px;
	z-index: 999;
}

header nav .menu li ul.submenu li {
	/*float: left;*/
	position: relative;
	margin: 0;
	padding: 0;
	display: block;
	width: 185px;
	/*height: 181px;*/
	overflow: hidden;
}
header nav .menu li ul.submenu li a {
	display: block;
}
header nav .menu li ul.submenu li img {
	display: block;
}
header nav .menu li ul.submenu li a .overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 1;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
header nav .menu li ul.submenu li a:hover .overlay {
	opacity: 1;
}
header nav .menu li ul.submenu li a .text {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	color: #fff;
	background: rgba(185,105,64,0.6);
	height: 39px;
	line-height: 39px;
	font-size: 14px;
	text-align: center;
	display: block;
	z-index: 3;
}
header nav .menu li ul.submenu li a:hover .text {
	color: #fff;
}
/* ****************************FOOTER *********************/
footer {
	background: url('../img/bg-footer.jpg') center center repeat;
	width: auto;
	display: block;
	padding: 20px 0 10px 0;
	overflow: hidden;
	font-family: 'ProximaNova semi', sans-serif;
}
footer.simple .col {
	width: 60%;
	float: left;
}
footer.simple .col.sec {
	width: 35%;
	float: right;
	text-align: right;
	line-height: 32px;
}
footer.simple .col .col {
	width: 33%;
}
footer p {
	color: #fff;
	font-size: 10px;
	line-height: 13px;
	text-transform: uppercase;
}
footer.simple .col.sec p {
	line-height: 32px;
	text-align: center;
}
footer p a {
	color: #fff;
}
footer p a:hover {
	color: #c66b44;
}
footer .social {
	margin: 0;
	text-align: center;
	font-size: 17px;
}
footer .social p,
footer.simple .col.sec .social  {
	line-height: 20px;
	margin: 0;
	padding: 0;
}
footer .social a {
	color: #fff;
	display: inline-block;
	*display: inline;
	zoom: 1;
	opacity: 1;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
footer .social a:hover {
	opacity: .5
}
footer.big {
	padding: 20px 0;
}
footer.big .col {
	float: left;
	height: 155px;
}
footer.big .col.first {
	width: 20%;
	border-right: 1px solid #797a7d;
}
footer.big .col.first p {
	line-height: 14px;
	padding: 10px 0 0 0;
}
footer.big .col.second {
	width: 20%;
	border-right: 1px solid #797a7d;
}
footer.big .col.second ul {
	display: block;
	margin: 30px auto;
	width: 100px;
}
footer.big .col.second ul li {
	color: #fff;
	font-size: 10px;
	line-height: 13px;
	text-transform: uppercase;
}
footer.big .col.second ul li a {
	color: #fff;
}
footer.big .col.second ul li a:hover {
	color: #c66b44;
}
footer.big .col.therd {
	width: 23%;
	text-align: center;
}
footer.big .col.therd .tit {
	padding: 70px 0 10px 0;
}
footer.big .col.fourth {
	width: 30%;
	text-align: right;
	float: right;
}
footer.big .col.fourth p {
	clear: both;
	display: block;
	padding: 60px 0 0 0;
}
footer.big .gotop {
	float: right;
	width: 60px;
	font-size: 10px;
	color: #c66b44;
	text-transform: uppercase;
	text-align: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	opacity: 1;
}
footer.big .gotop img {
	display: block;
	margin: 0 auto 3px auto;
}
footer.big .gotop:hover {
	opacity: 0.5;
}

/*********************************/


/*:::::::::: secciones ::::::::::::::*/
.bg-home {
	background: #38d4ac; 
  	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
}


.wrapper {
	width: 100%;
	padding: 0;
	margin: 0 auto;
	overflow: hidden;
}




/*    HOME   */
.homeslider {
	overflow: hidden;
	display: block;
	position: relative;
	min-height: 200px;
}
.homeslider .slides li {
	float: left;
}
.homeslider .slides li.slide01 {
	background: url('../img/home/homeslide01.jpg') center center no-repeat; 
  	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
}
.homeslider .slides li.slide02 {
	background: url('../img/home/homeslide02.jpg') center center no-repeat; 
  	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
}
.homeslider .slides li.slide03 {
	background: url('../img/home/homeslide03.jpg') center center no-repeat; 
  	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
}
/*.homeslider .slides img {
	width: 100%;
	display: block;
	position: absolute;
	bottom: 0;
}
.homeslider .slides .slide02 img {
	width: 100%;
	display: block;
	position: absolute;
	top: 0;
}*/
.homeslider .slides .col {
	float: left;
	width: 50%;
	position: relative;
	overflow: hidden;
}
.homeslider .flex-direction-nav {
	bottom: 0;
    display: block;
    height: 50px;
    left: 50%;
    margin: 0 0 0 -550px;
    position: absolute;
    width: 100%;
}
.homeslider a.flex-prev {
    color: #fff;
    font-size: 40px;
    left: 100%;
    margin-left: -140px;
    margin-top: -70px;
    opacity: 0.7;
    position: absolute;
    top: 100%;
    outline: none;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.homeslider a.flex-next {
    color: #fff;
    font-size: 40px;
    margin-right: 0;
    margin-top: -70px;
    opacity: 0.7;
    position: absolute;
    right: 0%;
    top: 100%;
    outline: none;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.homeslider a.flex-prev:hover,
.homeslider a.flex-next:hover {
	opacity: 1
}
.homeslider .slides li {
	position: relative;
}
.homeslider .slides li.slide01 .contentCenter {
	display: block;
    height: auto;
    margin: 0 auto 0 -550px;
    top: 0;
    left: 50%;
    position: absolute;
    z-index: 9;
}
.homeslider .slides li.slide01 h1 {
	font-family: "freight-big-pro", sans-serif;
	margin: 20% 0 0 0;
	display: block;
	font-size: 41px;
	line-height: 43px;
	text-transform: uppercase;
	font-weight: bold;
}
.homeslider .slides li .line {
	width: 100px;
	height: 1px;
	background: #fff;
	display: block;
	margin: 30px 0 20px 0;
}
.homeslider .slides li .box {
	width: 328px;
	height: 204px;
	padding: 45px;
	background: url('../img/bg-salmon.jpg') 0 0 repeat;
	position: absolute;
	top: 15%;
}
.homeslider .slides li.slide02 .box {
	left: 0;
}
.homeslider .slides li.slide03 .box {
	right: 0;
}
.homeslider .slides li .box h1 {
	font-family: "freight-big-pro", sans-serif;
	display: block;
	font-size: 27px;
	line-height: 30px;
	text-transform: uppercase;
	font-weight: bold;
}
.homeslider .slides li .box p {
	color: #fff;
	font-size: 17px;
	line-height: 21px;
	margin-bottom: 20px;
}
.homeslider .slides li .box .mas {
	display: inline-block;
	*display: inline;
	zoom: 1;
	text-align: center;
	font-size: 12px;
	border: solid 1px #fff;
	text-transform: uppercase;
	font-weight: 700;
	padding: 4px 35px;
	overflow: hidden;
	position: relative;
}
.homeslider .slides li .box .mas .text {
	position: relative;
	z-index: 3;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: rgb(255,255,255);
}
.homeslider .slides li .box .mas:hover .text {
	color: rgb(198,107,68);
}
.homeslider .slides li .box .mas .over {
	position: absolute;
	background: #fff;
	left: -100%;
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.homeslider .slides li .box .mas:hover .over {
	left: 0;
}
.homeslider .overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.7);
	z-index: -1;
	opacity: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.homeslider .overlay.active {
	opacity: 1;
	z-index: 10;
}
.homeslider  .flex-control-nav {
	position: absolute;
	margin: 0;
	padding: 0;
	display: block;
	text-align: center;
	bottom: 100px;
	left: 0;
	right: 0;
	overflow: hidden;
	width: 100%;
	z-index: 9;
}
.homeslider  .flex-control-nav li {
	display: inline-block;
	*display: inline;
	zoom: 1;
	width: 8px;
	margin: 0 5px;
}
.homeslider  .flex-control-nav li a {
	display: block;
	width: 8px;
	height: 8px;
	text-indent: -99999px;
	background: #fff;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	cursor: pointer;
}
.homeslider  .flex-control-nav li a.flex-active {
	background: #c96e47;
}

.openInfo {
	display: block;
	text-align: center;
	width: 100px;
	text-transform: uppercase;
	color: #fff;
	font-size: 11px;
	position: absolute;
	bottom: 30px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	opacity: 1;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	clear: both;
	z-index: 9;
}
.openInfo img {
	display: block;
	margin: 0 auto 5px;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.openInfo:hover {
	opacity: 0.5;
	color: #fff;
}

.overitems {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 0;
	overflow: hidden;
	z-index: 20;
	background: url('../img/bg-overitems.jpg') 0 0 repeat;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.overitems.open {
	height: 287px;
	overflow: visible;
}

.overitems .closeInfo {
	width: 29px;
	height: 29px;
	margin: -50px auto 0 auto;
	display: block;
}
.overitems .closeInfo img {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.overitems .content {
	width: auto;
	display: block;
	width: 728px;
	margin: 60px auto 0 auto;
	text-align: center;
}
.overitems .content a {
	float: left;
	width: 182px;
	position: relative;
}
.overitems .content a img {
	display: block;
}
.overitems .content a .over {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
}
.overitems .content a h3 {
	font-family: "freight-big-pro", sans-serif;
	font-size: 15px;
	line-height: 29px;
	display: block;
	color: #fff;
	text-transform: uppercase;
}
.overitems .content a span {
	font-size: 10px;
	text-transform: uppercase;
	display: inline-block;
	*display: inline;
	zoom: 1;
	color: #fff;
	opacity: 1;
	padding-bottom: 4px;
	letter-spacing: 2px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	
	@include breakpoint(laptop){
		opacity: 0;
	}
}

.overitems .content a:hover .over {
	opacity: 1;
	border-bottom: none;

}
.overitems .content a:hover span {
	opacity: 1;
	border-bottom: solid 1px #fff;
}

/********** INTERNAS ****************/
#current {
	position: fixed;
	top: 42%;
	right: 20px;
	z-index: 9;
}
#current a {
	display: block;
	text-align: right;
	color: #fff;
	margin-bottom: 5px;
	text-shadow: 0 0 3px #999;
}
#current a .num {
	display: inline-block;
	*display: inline;
	zoom:1;
	font-family: "freight-big-pro", sans-serif;
	font-size: 15px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
#current a .text {
	font-size: 14px;
	text-transform: uppercase;
	display: none;
	margin-right: 10px;
}
#current a.active .text,
#current a:hover .text {
	display: inline-block;
	*display: inline;
	zoom:1;
}
#current a.active .num {
	font-size: 29px;
}

.proyecto .bg-01 {
	background: url('../img/proyecto/bg01.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 555px;
	width: 100%;
	position: relative;
}
.proyecto .bg-02 {
	background: url('../img/proyecto/bg02.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 679px;
	width: 100%;
	position: relative;
}
.proyecto .bg-03 {
	position: relative;
}
.proyecto .bg-03 .bg {
	background: url('../img/proyecto/bg03.jpg') left center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 680px;
	width: 80%;
	float: right;
}
.proyecto .bg-04 {
	background: url('../img/proyecto/bg04.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 580px;
	width: 100%;
	position: relative;
	clear: both;
}
.tabTitle {
	width: 366px;
	height: 181px;
	background: url('../img/bg-tabtitle.jpg') 0 0 repeat; 
	float: left;
}
.tabTitle.large {
	width: 546px;
}
.tabTitle.fr {
	float: right;
}
.tabTitle h3 {
	display: block;
	padding: 20px 40px 16px 40px;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
}
.tabTitle h1 {
	font-family: "freight-big-pro", sans-serif;
	font-weight: bold;
	display: block;
	padding: 16px 40px;
	color: #fff;
	text-transform: uppercase;
	font-size: 26px;
	line-height: 26px;
}
.tabTitle .line {
	height: 1px;
	width: 40px;
	display: block;
	margin: 0 0 0 40px;
	background: #fff;
}

.proyecto .colmedium {
	position: absolute;
	bottom: 0;
	width: 546px;
	left: 50%;
	margin: 0 0 0 -273px;
}
img.box {
	float: left;
	display: block;
}
img.box.fr {
	float: right;
}
img.box.negative {
	margin-left: -180px;
}

.proyecto .coltext {
	position: absolute;
	top: 0;
	width: 546px;
	left: 50%;
	margin: 0 0 0 -273px;
}
.proyecto .coltext .col {
	width: 366px;
	background: #fff;
	padding-bottom: 20px;
	overflow: hidden;
}
.proyecto .coltext .col .line {
	height: 1px;
	width: 85px;
	display: block;
	margin: 40px;
	background: #000;
}
.proyecto .coltext .col p {
	color: #000;
	padding: 0 40px 20px 40px;
	font-size: 17px;
	line-height: 21px;
}

.septrama {
	background: url('../img/bg-trama.jpg') center center repeat; 
	height: 181px;
	width: 100%;
	position: relative;
}

.proyecto .leftcol {
	position: absolute;
	height: 680px;
	width: 50%;
	left: -273px;
	background: #1b1c20;
}
.proyecto .leftcol .content {
	float: right;
	margin: 155px 40px 0 0;
	width: 225px;
	text-align: right;
}
.proyecto .leftcol .line {
	height: 1px;
	width: 85px;
	display: block;
	float: right;
	clear: both;
	margin: 40px 0;
	background: #fff;
}
.proyecto .leftcol p {
	font-size: 17px;
	line-height: 21px;
	padding: 0 0 30px 0;
	display: block;
	clear: both;
}
.proyecto .bg-04 .tabTitle  {
	position: absolute;
	bottom: 0;
	background:  url('../img/bg-salmon.jpg') 0 0 repeat;
}
.proyecto .interes {
	width: 100%;
	height: 509px;
	padding: 40px 0;
	position: relative;
	overflow: hidden;
	background: url('../img/proyecto/bg-puntosinteres.jpg') center center repeat;
}
.proyecto .interes .line {
	height: 1px;
	width: 40px;
	display: block;
	margin: 20px 0;
	background: #fff;
}
.proyecto .interes .center {
	display: block;
	margin: 0 auto;
}
.proyecto .interes .openpunto {
	display: block;
	width: 160px;
	margin: 40px auto 0 auto;
	color: #fff;
	text-transform: uppercase;
	line-height: 30px;
}
.proyecto .interes .openpunto img {
	float: right;
}
.proyecto .interes .overmap {
	position: absolute;
	top: 0;
	right: -100%;
	left: 100%;
	bottom: 0;
	z-index: 2;
	background: url('../img/proyecto/bg-ubicacion.jpg') right center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	-webkit-transition: all 0.5s ease-in;
	-moz-transition: all 0.5s ease-in;
	-ms-transition: all 0.5s ease-in;
	-o-transition: all 0.5s ease-in;
	transition: all 0.5s ease-in;
}
.proyecto .interes .overmap.open {
	left: 0;
	right: 0;
}
.proyecto .interes .overmap .text {
	width: 286px;
	padding: 0 40px 40px 40px;
	background:  url('../img/bg-salmon.jpg') 0 0 repeat;
	float: left;
}
.proyecto .interes .overmap .text .line {
	width: 80px;
	margin-top: 0;
}
.proyecto .interes .overmap .text p {
	font-size: 17px;
	line-height: 20px;
	color: #fff;
	padding: 0 0 20px 0;
}
.proyecto .interes .overmap .closepunto {
	display: block;
	width: 100px;
	margin: 0 0 0 -50px;
	position: absolute;
	bottom: 20px;
	left: 50%;
	color: #fff;
	text-transform: uppercase;
	line-height: 30px;
}
.proyecto .interes .overmap .closepunto img {
	float: left;
	margin: 0 10px 0 0;
}
.proyecto .mapa {
	width: 100%;
	background: #fff;
	padding: 40px 0;
}
.proyecto .mapa p.tit {
	color: #000;
	font-size: 14px;
	text-transform: uppercase;
}
.proyecto .mapa .line {
	width: 40px;
	height: 1px;
	background: #000;
	display: block;
	margin: 30px 0;
}
.proyecto .mapa .referencias {
	float: right;
    position: absolute;
    right: 0;
    top: 0;
    width: 23%;
    z-index: 4;
}
.proyecto .mapa .referencias  p {
	color: #000;
	font-size: 14px;
	padding: 5px 0 0 0;
}
.proyecto .mapa .responsive-img {
	max-width: 100%;
	display: block;
	position: relative;
	z-index: 1;
}
.proyecto .mapa .navegacion {
	position: relative;
	float: left;
	width: 100%;
	margin-bottom: 60px;
}
.proyecto .mapa .navegacion .mapoverlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.5);
	z-index: 2;
}
.proyecto .mapa .navegacion .ref {
	position: absolute;
	z-index: 3;
	width: 30px;
	height: 30px;

}
.proyecto .mapa .navegacion .ref:hover {
	z-index: 99;
}
.proyecto .mapa .navegacion .ref .num {
	width: 30px;
	height: 30px;
	line-height: 30px;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	display: block;
	position: relative;
	background: #534f4f; /* Old browsers */
	background: -moz-linear-gradient(45deg,  #534f4f 0%, #1d1e23 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,#534f4f), color-stop(100%,#1d1e23)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(45deg,  #534f4f 0%,#1d1e23 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(45deg,  #534f4f 0%,#1d1e23 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(45deg,  #534f4f 0%,#1d1e23 100%); /* IE10+ */
	background: linear-gradient(45deg,  #534f4f 0%,#1d1e23 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#534f4f', endColorstr='#1d1e23',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	-webkit-border-radius: 15px 15px 15px 15px;
	border-radius: 15px 15px 15px 15px;
}
.proyecto .mapa .navegacion .ref .glob {
	text-align: center;
	position: absolute;
	width: 250px;
	height: 225px;
	left: -111px;
	top: -102px;
	overflow: hidden;
	opacity: 0;
	z-index: -9;
	border-radius: 150px;
	-moz-transform: scale(0);
	-webkit-transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}
.proyecto .mapa .navegacion .ref:hover {
	overflow: visible;
}
.proyecto .mapa .navegacion .ref:hover .glob {
	opacity: 1;
	z-index: 4;
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	
}
.proyecto .mapa .navegacion .ref .glob p {
	display: block;
	text-align: center;
	color: #000;
	font-size: 17px;
	padding: 0 20px 10px 20px;
}
.proyecto .mapa .navegacion .ref .glob img {
	width: 205px;
	border: solid 8px #333;
	-webkit-border-radius: 150px;
	border-radius: 150px;
}
.proyecto .mapa .navegacion .ref.uno {
	left: 47%;
    top: 81%;
}
.proyecto .mapa .navegacion .ref.dos {
	left: 53%;
    top: 87%;
}
.proyecto .mapa .navegacion .ref.tres {
	left: 37%;
    top: 63%;
}
.proyecto .mapa .navegacion .ref.cuatro {
	left: 41%;
    top: 73%;
}

.amenidades .colmedium {
	position: absolute;
	width: 50%;
	margin: 0;
}
.amenidades .colmedium.right {
	right: 0;
}
.amenidades .colmedium.bott {
	bottom: 0;
}
.amenidades img.box.negative {
	margin-left: -185px;
}
.amenidades .bg-01 {
	background: url('../img/amenidades/bg01.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 555px;
	width: 100%;
	position: relative;
}
.amenidades .bg-02 {
	background: url('../img/amenidades/bg02.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 678px;
	width: 100%;
	float: right;
	position: relative;
}
.amenidades .bg-03 {
	background: url('../img/amenidades/bg03.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 584px;
	width: 100%;
	position: relative;
}
.amenidades .bg-04 {
	background: url('../img/amenidades/bg04.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 584px;
	width: 100%;
	position: relative;
}
.amenidades .bg-05 {
	background: url('../img/amenidades/bg05.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 584px;
	width: 100%;
	position: relative;
}
.amenidades .bg-06 {
	background: url('../img/amenidades/bg06.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 584px;
	width: 100%;
	position: relative;
}
.amenidades .bg-07 {
	background: url('../img/amenidades/bg07.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 584px;
	width: 100%;
	position: relative;
}
.amenidades .infloclub {
	height: 678px;
	position: relative;
	width: 100%;
	clear: both;
	background: #222327;
}
.amenidades .infloclub .row {
	height: 225px;
	display: block;
	clear: both;
	width: 100%;
	margin-top: 1px;
}
.amenidades .infloclub .row.light {
	background: #39393d;
}
.amenidades .infloclub .contentText {
	width: 510px;
	float: left;
	margin: 0 0 0 40px;
}
.amenidades .infloclub .contentText .line {
	width: 85px;
	height: 1px;
	display: block;
	float: right;
	clear: both;
	background: #fff;
	margin: 50px 0;
}
.amenidades .infloclub .contentText p {
	font-size: 17px;
	line-height: 21px;
	padding: 0;
	text-align: right;
	display: block;
	clear: both;
}
.amenidades .infloclub ul.speks {
	display: block;
	margin: 50px 0 0 0;
}
.amenidades .infloclub ul.speks li {
	font-size: 17px;
	line-height: 21px;
	/*background: url('../img/amenidades/spec.png') 5px 10px no-repeat;*/
	padding: 0 0 0 20px;
	float: left;
	width: 45%;
	text-align: right;
	color: #fff;
}

.campo-golf .bg-01 {
	background: url('../img/golf/bg01.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 555px;
	width: 100%;
	position: relative;
}
.campo-golf .bg-02 {
	background: url('../img/golf/bg02.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 584px;
	width: 100%;
	position: relative;
}
.campo-golf .bg-03 {
	background: url('../img/golf/bg03.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 584px;
	width: 100%;
	position: relative;
}
.campo-golf .bg-04 {
	background: url('../img/golf/bg04.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 714px;
	width: 100%;
	position: relative;
}
.campo-golf .bg-05 {
	background: url('../img/golf/bg04.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 600px;
	width: 100%;
	position: relative;
}

.campo-golf .colmedium {
	position: absolute;
	width: 50%;
	margin: 0;
}
.campo-golf .colmedium.right {
	right: 0;
}
.campo-golf .colmedium.bott {
	bottom: 0;
}
.campo-golf img.box.negative {
	margin-left: -185px;
}


.campo-golf .absolutbottom {
	position: absolute;
	bottom: 0;
}

.campo-golf .coltext .col {
	width: 366px;
	background: #1b1c20;
	padding-bottom: 20px;
	overflow: hidden;
}
.campo-golf .coltext .col .line {
	height: 1px;
	width: 85px;
	display: block;
	margin: 10px 40px;
	background: #fff;
}
.campo-golf .coltext .col p {
	color: #fff;
	padding: 20px 40px;
	font-size: 17px;
	line-height: 21px;
}
.campo-golf .hoyos {
	height: 714px;
	width: 100%;
	clear: both;
	display: block;
}
.campo-golf .hoyos .block {
	height: 714px;
	background: url("../img/bg-header.jpg") repeat scroll center center; 
	width: 100%;
}
.campo-golf .hoyos .block .content {
	width: 480px;
	height: auto;
	margin: 40px 60px 0 0;
	float: right;
}
.campo-golf .hoyos .flex-control-nav {
	clear: both;
	overflow: hidden;
	margin: 40px 0;
}
.campo-golf .hoyos .flex-control-nav ol {
	float: left;
	width: 25%;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}
.campo-golf .hoyos .flex-control-nav ol li {
	line-height: 20px;
	cursor: pointer;
}
.campo-golf .hoyos .flex-control-nav ol li:hover,
.campo-golf .hoyos .flex-control-nav ol li.flex-active {
	color: #f08541;
}
.campo-golf .hoyos .hoyosslider {
	clear: both;
	display: block;
	width: 90%;
	height: auto;
	margin: 0 auto;
	position: relative;
}
.campo-golf .hoyos .hoyosslider .slides img {
	max-width: 100%;
	margin: 0 auto;
	display: block;
}
.campo-golf .hoyos .flex-prev {
	font-size: 40px;
	color: #fff;
	position: absolute;
	margin: 60px 0 0 0;
	left: -70px;
}
.campo-golf .hoyos .flex-next {
	font-size: 40px;
	color: #fff;
	position: absolute;
	margin: 60px 0 0 0;
	right: -70px;
}
.campo-golf .hoyos .note {
	padding: 60px 0 0 0;
	color: #fff;
	font-size: 16px;
	line-height: 21px;
}
/********** GALERIA ***************/
.galeria {
	overflow: hidden;
	display: block;
	position: relative;
	min-height: 200px;
}
.galeria .slides li {
	float: left;
}
.galeria .slides img {
	width: 100%;
	display: block;
}
.galeria .slides .col {
	float: left;
	width: 50%;
	position: relative;
	overflow: hidden;
}
.galeria .flex-direction-nav {
	bottom: 0;
    display: block;
    height: 50px;
    left: 50%;
    margin: 0 0 0 -550px;
    position: absolute;
    width: 100%;
}
.galeria a.flex-prev {
    color: #fff;
    font-size: 40px;
    left: 100%;
    margin-left: -65px;
    margin-top: -70px;
    opacity: 0.7;
    position: absolute;
    top: 0%;
    outline: none;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.galeria a.flex-next {
    color: #fff;
    font-size: 40px;
    margin-right: 0;
    margin-top: -70px;
    opacity: 0.7;
    position: absolute;
    right: 0%;
    top: 0%;
    outline: none;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.galeria a.flex-prev:hover,
.galeria a.flex-next:hover {
	opacity: 1
}

.submenuText {
	display: block;
	width: 100%;
	background: #000;
	height: 29px;
}
.submenuText ul {
	float: right;
	text-align: right;
}
.submenuText ul li {
	display: inline-block;
	*display: inline;
	zoom: 1;
	line-height: 29px;
	text-transform: uppercase;
	font-size: 11px;
	color: #fff;
	margin-left: 20px;
}
.submenuText ul li a {
	color: #ffffff;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.submenuText ul li a:hover,
.submenuText ul li a.active {
	text-decoration: none;
	color: #c66b44;
}

.newsContent {
	width: 921px;
	margin: 30px auto;
	display: block;
	overflow: hidden;
}
.newsContent .cargamas {
	color: #c66b44;
	text-transform: uppercase;
	display: block;
	text-align: center;
	width: 150px;
	height: auto;
	font-size: 12px;
	margin: 20px auto;
	text-decoration: underline;
}
.newsContent .cargamas img {
	display: block;
	margin: 0 auto 10px auto;
}
.new {
	padding:40px 0;
	width: 100%;
	display: block;
	overflow: hidden;
	border-bottom: solid 1px #c66b44;
}
.new.last {
	border-bottom: none;
}
.new .image {
	float: left;
	width: 363px;
	height: 237px;
}
.new .info {
	float: left;
	width: 350px;
	height: auto;
	margin:  0 0 0 40px;
}
.new h2,
.contact h2 {
	font-size: 20px;
	color: #c66b44;
	text-transform: uppercase;
	line-height: 35px;
	font-weight: normal;
}
.new h1,
.contact h1 {
	font-size: 30px;
	color: #c66b44;
	text-transform: uppercase;
	line-height: 30px;
	font-weight: normal;
	font-family: "freight-big-pro", sans-serif;
}
.new .line,
.contact .line {
	width: 80px;
	height: 1px;
	margin: 20px 0;
	background: #c66b44;
	display: block;
}
.new .date {
	font-size: 13px;
	color: #c66b44;
	line-height: 17px;
	display: block;
	padding: 5px 0 10px 0;
}
.new p {
	font-size: 17px;
	line-height: 21px;
	padding: 0 0 20px 0;
	color: #1b1c20;
}
.new .info a {
	text-transform: uppercase;
	color: #c66b44;
	font-size: 11px;
}
.new .textcolumns {
	-moz-column-count: 2;
	-moz-column-gap: 25px;
	-webkit-column-count: 2;
	-webkit-column-gap: 25px;
	column-count: 2;
	column-gap: 25px;
	margin-top: 40px;
}
.new .responsive-img {
	max-width: 100%;
	display: block;
	margin: 20px 0 0 0;
}
.backnews {
	width: 250px;
	height: 29px;
	line-height: 29px;
	display: block;
	text-transform: uppercase;
	color: #c66b44;
	font-size: 11px;
	margin: 30px 0 0 0;
}
.backnews img {
	float: left;
	margin: 0 10px 0 0;
}
.backnews:hover {
	opacity: 0.5;
	color: #c66b44;
}

.contact {
	color: #1b1c20;
}
.contact .bg-01 {
	background: url('../img/contacto/bg01.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 714px;
	width: 35%;
	float: left;
	position: relative;
}
.contact .content {
	float: left;
	width: 65%;
	height: auto;
}
.contact .form-items {
	display: block;
	margin: 40px 0 0 60px;
	width: 520px;
}

label.error {
	display: none !important;
}
.contact .formu {
	margin: 40px 0 0 0;
	display: block;
}
.contact input {
	display: block;
	background: #fff;
	border: solid 1px #1b1c20;
	height: 26px;
	line-height: 26px;
	font-size: 12px;
	text-indent: 10px;
	width: 99%;
	margin: 0 0 15px 0;
	outline:0px;
}
.contact .col {
	width: 48%;
	float: left;
}
.contact .col.sec {
	float: right;
}
.contact label {
	font-size: 12px;
	display: block;
	padding: 0 0 5px 0;
}

.contact select {
	display: block;
	background: #fff;
	border: solid 1px #1b1c20;
	height: 30px;
	line-height: 28px;
	font-size: 12px;
	text-indent: 2px;
	width: 100%;
	margin: 0 0 15px 0;
	outline:0px;
}
.contact textarea {
	display: block;
	background: #fff;
	border: solid 1px #1b1c20;
	height: 80px;
	line-height: 14px;
	font-size: 12px;
	text-indent: 10px;
	width: 99%;
	margin: 0 0 15px 0;
	resize: none;
	outline:0px;
}
.contact input.error,
.contact select.error,
.contact textarea.error {
	border: solid 1px #e43d1d;
}

.contact .send,
.contact .send-ib {
	display: block;
	background: #fff;
	border: solid 1px #1b1c20;
	height: 26px;
	line-height: 26px;
	color: #1b1c20;
	font-size: 10px;
	text-transform: uppercase;
	width: 75px;
	margin: 0;
	float: right;
	font-weight: bold;
	position: relative;
	overflow: hidden;
}
.ua-chrome .contact .send,
.ua-chrome .contact .send-ib {
	line-height: 24px;
}
.contact .send .text,
.contact .send-ib .text {
	position: relative;
	z-index: 3;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: rgb(0,0,0);
}
.contact .send:hover .text,
.contact .send-ib:hover .text {
	color: rgb(255,255,255);
}
.contact .send .over,
.contact .send-ib .over {
	position: absolute;
	background: rgb(198,107,68);
	left: -100%;
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.contact .send:hover .over,
.contact .send-ib:hover .over {
	left: 0;
}
.errortext {
	display: none;
	color: #e43d1d;
}
.contact .req {
	display: block;
	clear: both;
	text-align: right;
	font-size: 11px;
	padding: 10px 0 0 0;
}
.contact .thanks {
	display: none;
	margin: 40px 0 0 0;
	font-size: 14px;
	line-height: 22px;
	width: 360px;
}
.contact .thanks .back {
	width: 250px;
	height: 29px;
	line-height: 29px;
	display: block;
	color: #000;
	font-size: 12px;
	margin: 40px 0 0 -35px;
}
.contact .thanks .back img {
	float: left;
	margin: 0 10px 0 0;
}
.contact .thanks .back:hover {
	color: #c66b44;
}
.contact .thanks.succes {
	display: block;
}
.contact .formu.succes {
	display: none;
}

.equipo {
	background-color: #1b1c20;
	overflow: hidden;
}
.equipo .bg-01 {
	background: url('../img/equipo/bg01.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 780px;
	width: 50%;
	float: left;
	position: relative;
}
.equipo .content {
	float: left;
	width: 50%;
	height: auto;
}
.equipo .box1 {
	background:  url('../img/bg-salmon.jpg') 0 0 repeat;
	width: 100%;
	padding: 40px 0;
}
.equipo .box2 {
	width: 100%;
	padding: 40px 0;
}
.equipo .text {
	width: 450px;
	padding: 0 0 0 45px;
}
.equipo .text .line {
    background: none repeat scroll 0 0 #fff;
    display: block;
    height: 1px;
    margin: 30px 0;
    width: 85px;
}
.equipo h2 {
    color: #fff;
    display: block;
    font-family: "freight-big-pro",sans-serif;
    font-size: 26px;
    font-weight: bold;
    line-height: 26px;
    padding: 0;
    text-transform: uppercase;
}
.equipo .text p {
	color: #fff;
	font-size: 17px;
	line-height: 20px;
}
.equipo .box2 .text p {
	padding: 30px 0;
}
.equipo .box2 .center {
	display: block;
	margin: 0 auto;
}

/***************************** MENU MOBILE DESPLEGABLE ********************/
.menuMobile {
	background: url('../img/bg-salmon.jpg') 0 0 repeat;
	width: 0px;
	height: 100%;
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	overflow: hidden;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.menuMobile.open,
.menuMobile .contmenu {
	width: 310px;
}
.menuMobile .contmenu {
	display: block;
	height: auto;
}
.overlaypage {
	z-index: -99;
	background: rgba(0,0,0,0.8);
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.overlaypage.open {
	opacity: 1;
	z-index: 998;
}

.openMenu {
	clear: both;
	display: block;
	width: 33px;
	height: 17px;
	margin: 30px 0 0 0 !important;
}
.closeMenu {
	display: block;
	width: 28px;
	height: 27px;
	position: relative;
	float: right;
	margin: 75px 75px 0 0;
}
.menuMobile .icon {
	float: left;
	margin: 20px 0 0 30px;
}
.menuMobile .menu {
	clear: both;
	display: block;
	margin: 40px 110px 0 0;
}
.menuMobile .menu li {
	display: block;
	text-align: right;
	font-size: 16px;
	padding: 0 0 30px 0;
	clear: both;
	overflow: hidden;
}
.menuMobile .menu li .broch {
	font-size: 13px;
}

.menuMobile .menu li.cont a {
	border: solid 1px #fff;
	padding: 4px 10px;
	float: right;
	position: relative;
	overflow: hidden;
}
.menuMobile .menu li.cont a .text {
	position: relative;
	z-index: 3;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: rgb(255,255,255);
}
.menuMobile .menu li.cont a:hover .text,
.menuMobile .menu li.cont a.active .text {
	color: rgb(198,107,68);
}
.menuMobile .menu li.cont a .over {
	position: absolute;
	background: #fff;
	left: -100%;
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.menuMobile .menu li.cont a:hover .over,
.menuMobile .menu li.cont a.active .over {
	left: 0;
}

.real-estate .colmedium {
	position: absolute;
	width: 50%;
	margin: 0;
}
.real-estate .colmedium.right {
	right: 0;
}
.real-estate .colmedium.bott {
	bottom: 0;
}
.real-estate img.box.negative {
	margin-left: -185px;
}
.real-estate .bg-01 {
	background: url('../img/realestate/bg01.jpg') center center no-repeat fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 555px;
	width: 100%;
	position: relative;
}
.real-estate .bg-02 {
	background: url('../img/realestate/bg04.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.real-estate .bg-03 {
	background: url('../img/realestate/bg02.jpg') center center no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.real-estate .bg-04 {
	background: url('../img/realestate/bg03.jpg') center right no-repeat; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.real-estate .septrama {
	height: auto;
	overflow: hidden;
}
.real-estate .columns {
	overflow: hidden;
	float: left;
	width: 50%;
	min-height: 100px;
}
.real-estate .row .columns {
	height: 628px;
}
.real-estate .columns.color {
	background: url('../img/bg-salmon.jpg') 0 0 repeat;
}
.real-estate .columns .text {
	width: 550px;
	padding: 40px 0;
}
.real-estate .row .columns .text {
	width: 440px;
	padding: 100px 60px 0;
}

.real-estate .columns.negative {
	margin-left: -185px;
}
.real-estate .columns .line {
	height: 1px;
	width: 85px;
	display: block;
	margin: 10px 0;
	background: #fff;
}
.real-estate .columns p {
	color: #fff;
	padding: 20px 0;
	font-size: 17px;
	line-height: 21px;
}

.real-estate .columns .text h2 {
    color: #fff;
    display: block;
    font-family: "freight-big-pro",sans-serif;
    font-size: 26px;
    font-weight: bold;
    line-height: 26px;
    padding: 20px 0;
    text-transform: uppercase;
}
.real-estate .row .columns.short {
	display: none;
}
.real-estate .row .columns.big {
	display: block;
}

.legales {
	display: none;
	padding: 20px;
	background: url('../img/bg-salmon.jpg') center center repeat;
	width: 700px;
	height: auto;
}
.modal h3 {
    color: #fff;
    display: block;
    font-size: 14px;
    padding: 0;
    text-transform: uppercase;
}
.modal .line {
    background: none repeat scroll 0 0 #fff;
    display: block;
    height: 1px;
    margin: 30px 0;
    width: 80px;
}
.modal p {
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
	line-height: 16px;
	padding: 0 0 20px;
}
.fancybox-inner {
	overflow-x: hidden !important;
}
.creditos {
	display: none;
	padding: 20px;
	background: url('../img/bg-salmon.jpg') center center repeat;
	width: 400px;
	height: auto;
}


/************************************************************************************************************************************
*********************************   RESPONSIVE ************************************************************************************
************************************************************************************************************************************
*********************************************************************************************************************************/

.desk {
	display: none !important;
}
.mob {
	display: block !important;
}


@media (max-width: 767px) {

}
@media (min-width: 768px) {
	
}
@media (max-width: 1024px) {
	.homeslider .slides li {
		height: 700px;
	}
	/*.homeslider .slides li img {
		width: auto;
		height: 100%;
	}

	.homeslider .slides .slide02 img {
	    position: relative;
	    top: auto;
	    width: auto;
	}*/

	.homeslider .slides li.slide01 .contentCenter {
	    left: 3%;
	    margin: 0;
	    top: 35%;
	}
	.proyecto .leftcol .content {
		width: 178px;
	}
	.galeria {
		height: auto !important;
	}
	.campo-golf .hoyos .block .content {
		width: 400px;
	}
	.amenidades .infloclub .contentText {
	    margin: 0 0 0 20px;
	    width: 450px;
	}
	.prlx01,.prlx02,.prlx03,.prlx04,.prlx05,.prlx06 {
		background-attachment: local !important;
		background-position: center center !important;
	}

	.real-estate .row .columns .text {
	    padding: 100px 40px 0;
	    width: auto;
	}

}
@media (max-width: 768px) {	
	.pref {
		display: none;
	}
	.none {
		display: none;
	}
	#current {
		display: none;
	}
	.homeslider {
		overflow: visible;
	}
	/*.homeslider .slides li {
		height: 850px !important;
	}*/
	footer.simple .col {
		width: 100%;
		margin: 0 auto;
		float: none;
		text-align: center;
	}
	footer.simple .col .col {
		width: 50%;
		float: left;
	}
	footer.simple .col.sec {
		width: 100%;
		clear: both;
		float: none;
		margin: 0 auto;
		text-align: center;
	}
	footer.simple .col.sec .col {
		width: 50%;
	}
	.proyecto .coltext {
		margin: 0;
		left: auto;
		width: 100%;
	}
	.proyecto .coltext .col {
		margin: 0 auto;
	}
	.proyecto .leftcol {
		left: 0;
	}
	.proyecto .leftcol .content {
	    padding: 0 0 0 50px;
	    width: auto;
	}
	.proyecto .colmedium {
		margin: 0;
		left: auto;
		width: 100%;
	}
	.proyecto .colmedium.section01 .tabTitle {
		display: block;
		float: none;
		margin: 0 auto;
	}
	.proyecto .leftcol .content {
		margin-top: 260px;
	}
	.proyecto .tabTitle.large {
	    margin-bottom: -181px;
	    width: 50%;
	}
	.proyecto #valores.septrama {
		height: 0;
		z-index: 9;
	}
	.proyecto .ref {
		display: none;
	}
	.prlx01,.prlx02,.prlx03,.prlx04,.prlx05,.prlx06 {
		background-attachment: local !important;
		background-position: center center !important;
	}
	footer.big .col.first,
	footer.big .col.second,
	footer.big .col.therd,
	footer.big .col.fourth {
		width: 24.5%;
	}

	.campo-golf .hoyos .block .content {
	    width: 430px;
	    margin-right: 25px;
	}
	.campo-golf .hoyos .flex-prev {
		left: -40px;
	}
	.campo-golf .hoyos .flex-next {
		right: -40px;
	}
	#pete.septrama {
		z-index: 9;
		height: 0;
	}
	#pete.septrama .box {
		display: none;
	}
	#pete.septrama .tabTitle {
		margin-top: -181px;
	}
	.campo-golf .bg-02 {
		height: 737px;
	}
	.campo-golf .bg-03 {
		height: 690px;
	}
	.campo-golf .coltext .col .line {
		display: none;
	}
	.campo-golf .hoyos .colmedium {
		width: 65%;
	}
	.campo-golf .hoyos .colmedium.right {
		width: 35%;
	}
	.amenidades .septrama {
		height: 0px;
		overflow: visible;
		z-index: 99;
	}
	.amenidades .bg-02 {
		display: none;
	}
	.amenidades .infloclub .colmedium {
		width: 100%;
	}
	.amenidades .infloclub .contentText {
		width: auto;
		margin-right: 30px;
	}
	.amenidades .colmedium {
		z-index: 9;
	}
	.amenidades .bg-03 .colmedium.right {
		right: auto;
		left: 20px;
	}
	.amenidades .bg-03 .colmedium.right .tabTitle {
		margin-bottom: -181px;
	}

	.galeria .slides li {
		height: 750px;
	}
	.galeria .slides img {
		width: auto;
		height: 100%;
	}
	.galeria .flex-direction-nav {
		top: 50%;
		left: 0;
		margin: 0;
		bottom: auto;
	}
	.galeria a.flex-prev {
		margin-left: 0;
		left: 20px;
	}
	.galeria a.flex-next {
		right: 20px;
	}
	.newsContent {
		width: auto;
		padding: 0 15px;
	}
	.new .image {
		width: 40%;
	}
	.new .image img {
		max-width: 100%;
	}
	.new .info {
		width: 45%;
		margin-left: 5%;
	}
	.contact .bg-01 {
		display: none;
	}
	.contact .content {
	    float: none;
	    height: auto;
	    min-height: 520px;
	    margin: 0 auto 100px auto;
	    width: 80%;
	    padding-bottom: 40px;
	    background: #fff;
	}
	.contact .form-items {
	    display: block;
	    margin: 0;
	    overflow: hidden;
	    padding: 30px;
	    width: auto;
	}
	.contact .col {
		width: 47%;
		float: left;
	}
	.contact .thanks .back {
		margin-left: 0;
	}
	.bg-contact {
		background: url('../img/golf/bg04.jpg') center center no-repeat; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
	.equipo {
		width: 550px;
		height: auto;
		display: block;
		margin: 0 auto 100px auto;
	}
	.bg-equipo {
		background: url('../img/equipo/bg01.jpg') center center no-repeat; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
	.equipo .bg-01 {
		display: none;
	}
	.equipo .content {
		float: none;
		margin: 0 auto;
		display: block;
		width: 100%;
	}
	.proyecto .mapa .navegacion {
	    display: block;
	    float: none;
	    width: 100%;
	}

	.real-estate .colmedium.right {
		right: auto;
		left: 40px;
	}
	.real-estate .columns.negative {
		margin-left: 40px;
		width: auto;
	}

}
@media (max-width: 767px) {
	.proyecto .mapa .referencias {
	    float: none;
	    display: block;
	    margin: 0 auto;
	    padding-top: 20px;
	    width: auto;
	    position: relative;
	}
	.wrapper.home {
		overflow: visible;
	}
	.menuMobile .menu {
		margin: 0 75px 0 0;
	}
	.menuMobile.open {
	    width: 275px;
	    overflow: auto;
	}
	.menuMobile .contmenu {
		width: 275px;
	}
	.closeMenu {
		margin-top: 25px;
	}
	.menuMobile .menu li {
		font-size: 14px;
		padding-bottom: 15px;
	}
	header {
		height: 90px;
	}
	header nav {
		width: 50%;
	}
	header .logo {
		width: 80px;
		height: auto;
	}
	.openMenu {
		margin-top: 15px !important;
	}
	header .logo img {
		width: 80px;
	}
	.homeslider .slides li.slide01 .contentCenter {
		top: 0;
	}
	.homeslider .slides li.slide01 h1 {
		font-size: 25px;
		line-height: 25px;
	}
	.homeslider .slides li .line {
		margin: 20px 0;
	}
	.homeslider .flex-control-nav {
		bottom: 75px;
	}
	.homeslider .slides li .box {
		width: auto;
		height: 220px;
		top: 10%;
		padding: 20px;
	}
	.openInfo {
		bottom: 15px;
	}
	.overitems {
		position: absolute;
		top: 100%;
		height: 0;
	}
	.overitems.open {
		top: 100%;
		height: 850px;
		background: url('../img/bg-salmon.jpg') 0 0 repeat;
	}
	.overitems .content {
		width: 100%;
	}
	.overitems .content a {
		display: block;
		float: none;
		margin: 15px auto 0 auto;
	}
	.overitems .content a span {
		margin-bottom: 1px;
	}
	.overitems .content a:hover span {
		margin-bottom: 0;
	}

	footer.big .col.first {
		width: 45%;
		margin-top: 40px;
		padding-right: 5%;
	}
	footer.big .col.second {
		width: 44%;
		padding-left: 5%;
		margin-top: 40px;
	}
	footer.big .col.second {
		border-right: none;
	}
	footer.big .col.fourth {
		width: 0%;
	}
	/******/
	#current {
		right: 10px;
	}
	.proyecto .colmedium {
		bottom: auto;
	}
	.tabTitle,
	.tabTitle.large,
	.proyecto .coltext .col,
	.proyecto .tabTitle.large {
		width: 100%;
	}
	.tabTitle h1 span {
		display: block;
	}
	.proyecto .leftcol {
		width: 100%;
		height: 290px;
		clear: both;
		position: relative;
	}
	.proyecto .coltext .col p,
	.proyecto .leftcol p,
	.proyecto .interes .overmap .text p {
		font-size: 15px;
		line-height: 19px;
	}
	.proyecto .coltext .col {
		padding-top: 20px;
		height: 360px;
	}
	.proyecto .bg-02 {
		height: 400px;
	}
	.proyecto .interes .overmap .closepunto {
		width: 30px;
		height: 30px;
		overflow: hidden;
		margin-left: -15px;
	}
	.proyecto .leftcol .line,
	.proyecto .coltext .col .line,
	.proyecto .interes .overmap .text .line {
		display: none;
	}
	.proyecto .bg-03 .bg {
		height: 400px;
		width: 100%;
		clear: both;
	}
	.proyecto .leftcol .content {
		margin: 0 auto;
		padding: 20px;
		text-align: left;
	}
	.proyecto .bg-04 .tabTitle {
		margin-left: -20px;
	}
	.proyecto .bg-03 .bg {
	    background: url("../img/proyecto/bg03.jpg") no-repeat scroll center 120px / cover rgba(0, 0, 0, 0);
	}
	.proyecto .interes {
		height: auto;
	}
	.proyecto .interes .center {
		max-width: 100%;
	}
	.proyecto .interes .overmap {
		background:  url('../img/bg-salmon.jpg') 0 0 repeat;
	}
	.proyecto .interes .overmap .text {
		width: auto;
		padding: 0 20px;
	}

	/******/
	.campo-golf  .contentCenter {
		padding: 0;
	}
	.campo-golf .coltext .col {
		width: 100%;
	}
	.campo-golf .bg-02 {
		height: 990px;
	}
	#pete .box {
		display: none;
	}
	.campo-golf .bg-03 .coltext .col {
		background: #fff;
	}
	.campo-golf .bg-03 .coltext .col p {
		color: #000;
	}
	.campo-golf .colmedium {
		width: 100%;
	}
	.campo-golf .coltext .col p {
		padding: 20px;
	}
	.campo-golf .bg-03 {
		height: 1000px;
	}
	.campo-golf .hoyos .colmedium {
		width: 100%;
	}
	.campo-golf .hoyos .colmedium.right {
		display: none;
	}
	.campo-golf .hoyos .block .content {
		width: auto;
		margin: 0 10px;
		padding-top: 20px;
	}
	.campo-golf .hoyos .flex-next,
	.campo-golf .hoyos .flex-prev {
		font-size: 30px;
	}
	.campo-golf .hoyos .flex-next {
	    right: -20px;
	}
	.campo-golf .hoyos .flex-prev {
	    left: -20px;
	}
	.campo-golf .hoyos .flex-control-nav ol li {
		font-size: 11px;
	}
	.campo-golf .hoyos .note {
		font-size: 14px;
		line-height: 18px;
	}
	/******/
	.amenidades .colmedium {
		width: 100%;
	}
	.amenidades .infloclub .row {
		height: auto;
		overflow: hidden;
	}
	.amenidades .infloclub ul.speks li {
		float: none;
		width: auto;
	}
	.amenidades .infloclub .contentText {
		margin: 0;
		padding: 20px;
	}
	.amenidades .infloclub ul.speks {
		margin: 0;
	}
	.amenidades .infloclub .contentText .line {
		display: none;
	}
	.amenidades .infloclub {
		height: 635px;
	}
	.amenidades .infloclub ul.speks li {
		font-size: 14px;
		text-align: left;
		padding-left: 0;
	}
	.amenidades .infloclub .contentText p {
		font-size: 15px;
		text-align: left;
	}
	.amenidades .bg-03 .colmedium.right {
		left: 0;
	}
	/*****/
	.galeria .slides li {
	    height: 450px;
	}
	/******/
	.bg-equipo {
	    background: none;
	}
	.equipo .text {
		width: auto;
		margin: 0;
		display: block;
		padding: 20px;
	}
	.equipo .box1 {
		padding: 0;
	}
	.equipo .box1  .text img {
		max-width: 100%;
	}
	.equipo {
		display: block;
		width: auto;
		padding: 0;
		margin: 0 auto;
	}
	/*******/
	.newsContent .top img.box {
		display: none;
	}
	/******/
	.contact .content {
		width: 90%;
	}
	.contact .col {
		width: 100%;
	}
	.contact .thanks {
		width: auto;
	}
	/********/
	.real-estate .row .columns {
	    height: 470px;
	    width: 100%;
	    clear: both;
	    float: none;
	}
	.real-estate .columns .text {
		width: auto;
	}
	.real-estate .row .columns .text {
		padding-top: 50px;
	}
	.real-estate .colmedium.right {
	    left: 0;
	    right: auto;
	    top: 0;
	    width: 100%;
	}
	.real-estate .row .columns.short {
		display: block;
	}
	.real-estate .row .columns.big {
		display: none;
	}
	.legales, .creditos {
		width: auto;
	}

	.galeria .slides li {
		overflow: hidden;
	}
	.galeria .slides img {
		position: relative;
		width: auto;
		height: 100%;
		left: 50%;
    margin: 0 0 0 -400px;
	}

}
@media (max-width: 400px) {
	.campo-golf .bg-05 {
		background-position: -145px 0 !important;
	}
	.campo-golf .bg-02 {
		background-position: -442px 169px !important;
	}
	.amenidades .bg-04 {
		background-position: -400px 164px;
	}
}
@media (min-width: 1024px) {
	.desk {
		display: block !important;
	}
	.mob {
		display: none !important;
	}

}
@media (min-width: 1100px) {
	.contentCenter {
		width: 1100px;
		margin: 0 auto;
		padding: 0;
	}
	header nav .menu li {
		padding: 0 0 0 13px;
	}
	.homeslider .flex-direction-nav,
	.galeria .flex-direction-nav {
	    width: 1100px;
	}
}